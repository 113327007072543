<template>
  <div class="whole">
    <title-two url="/login" title="忘记密码"/>

    <div class="vanCellStyle1">
      <div>
        <ul class="ulList">
          <li class="liStyle">
            <p>用户名</p>
            <input
              v-model="dataForm.username"
              placeholder="请输入用户名"
              class="inputStyle"
              type="text"
            />
          </li>
          <li class="liStyle">
            <p>手机号码</p>
            <input
              v-model="dataForm.phone"
              placeholder="请输入手机号码"
              class="inputStyle"
              type="text"
            />
          </li>

          <li class="liStyle">
            <div class="inputPapaStyle">
              <p>验证码</p>

              <van-row type="flex" justify="left">
                <van-col span="18">
                  <input
                    style="width: 100%"
                    v-model="dataForm.code"
                    placeholder="请输入验证码"
                    class="inputStyle"
                    type="text"
                  />
                </van-col>
                <van-col span="6">
                  <van-row type="flex" justify="end">
                    <span class="inputSpan" @click="verificationCode"
                      >获取验证码
                      <span v-if="codeSending">({{ theTime }})</span>
                    </span>
                  </van-row>
                </van-col>
              </van-row>
            </div>
          </li>

          <li class="liStyle">
            <p>设置密码</p>
            <input
              v-model="dataForm.password"
              placeholder="8-12位大小写字母+数字结合"
              class="inputStyle"
              type="password"
            />
          </li>
          <li class="liStyle">
            <p>确认密码</p>
            <input
              v-model="dataForm.repassword"
              placeholder="请输入登陆密码"
              class="inputStyle"
              type="password"
            />
          </li>

          <li class="liStyle">
            <div class="btnDivStyle">
              <van-button @click="resetPassword" class="btnStyle" type="primary"
                >重置密码</van-button
              >
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="vanCellStyle2">
      <!-- <van-cell title="手机号" is-link />
      <van-cell title="修改密码" is-link />
      <van-cell title="修改交易密码" is-link /> -->
    </div>
  </div>
</template>

<script lang="ts">
  import TitleTwo from "../../../components/titleTwo";
export default {
  components: {TitleTwo},
  name: "registerPage",
  data() {
    return {
      // 手机号

      isVerificationShow1: false,
      info: "",

      inputValue: "",
      inputCode: "",
      haveValue: false,
      haveCode: false,
      sid: "", //session id
      nation: "86", //country code
      userInput: false,
      errorInputShow: false,
      codeSending: false,
      theTime: 60,

      phoneNum: "",
      dataForm: {
        username: "",
        password: "",
        repassword: "",
        sid: "",
        code: "",
        phone: "",
      },
    };
  },
  methods: {
    // 校验
    // 1.校验用户名
    checkUserName() {},

    // 获取验证码
    verificationCode() {
      if (!this.dataForm.phone) {
        this.$toast("手机号错误");
        return;
      }
      this.haveValue = false;
      if (this.codeSending) {
        return;
      }

      this.theTime = 60;
      this.codeSending = true;
      this.doCountDown();

      this.$post2("Security/Index/userphonecode", {
        phone: this.dataForm.phone,
        username: this.dataForm.username,
      }).then((res) => {
        this.dataForm.sid = res.sid;
        console.log();
      });
    },
    doCountDown() {
      if (this.theTime > 1) {
        this.theTime--;
        this.codeTimer = setTimeout(() => {
          this.doCountDown();
        }, 1000);
      } else {
        this.codeSending = false;
        this.haveValue = true;
      }
    },
    onClickLeft() {
      this.$router.push("/login");
    },
    // 忘记密码接口
    resetPassword() {
      console.log(this.dataForm);
      this.checkUserName();
      this.$post2("user/api/passport/resetpwd", this.dataForm).then((res) => {
        console.log(res);
        this.$toast("修改成功，请重新登录");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.inputSpan {
  font-size: 0.2rem;
  color: #FF4B04;
}
.endList {
  margin-top: 0.7rem;
}
.btnDivStyle {
  margin-top: 1.7rem;
  margin-bottom: 1rem;
  text-align: center;
}
.btnStyle {
  border: 0px;
  border-radius: 5px;
  margin: 0.3rem;
  // width: 3rem;
  background: #FF4B04;
}

.bodyStyle {
  background: #f1f1f1;
  height: 100%;
}

.vanCellStyle1 {
  margin-left: 0rem;
  margin-right: 0rem;
  border-radius: 5px;
  margin-top: 0rem;
  /* background: #f1f1f1; */
}

.vanCellStyle2 {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  border-radius: 5px;
  margin-top: 0.3rem;
  /* background: #f1f1f1; */
}

.van-icon-arrow-left {
  color: #ffffff;
}

.van-hairline--bottom {
  background: #ecbb34;
  color: #ffffff;
}

.van-nav-bar__title {
  background: #ecbb34;
  color: #ffffff;
}

/* .van-ellipsis {
  background: 3d8051;
} */

.ulList {
  padding: 0.3rem;
  background: #ffffff;
  border-radius: 5px;
  // border-top: 0px;
}
.inputStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}
.inputStyle2 {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
  float: left;
}

.posRight {
  border: none;
  height: 0.6rem;
  padding: 0 0.26rem;
  border-radius: 0.8rem;
  color: #ffffff;
  font-size: 0.24rem;
  line-height: 0.6rem;
  background-color: #5867f0;
  float: right;
}

.whole {
  background: #ffffff;
}
.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}
.bottomBtnStyle {
  width: 100%;
  background: #e1c364;
  border-radius: 5px;
  border: 0px;
}
.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}
.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
</style>