<template>
	<div class="whole">
		<!-- <title-two url="/widthdrawMoneyInfo" title="安全验证" /> -->
		<!-- <common-head :title="$t('tba.SecurityVerification')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('tba.SecurityVerification')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="layoutBox bgWhite">
				<div class="securityCheck">
					<div class="top">
						<div class="caption mainFontColor">{{ $t('tba.codeHasSend') }}</div>
						<div class="phone mainFontLightColor">+{{nation}} {{ phoneNum }}</div>
					</div>
					<div class="verifyCode">
						<input v-model="verifyCode" @input="inputVerifyCode" class="inp" type="text" :placeholder="$t('login.PleaseEnterVerificationCode')" />
						<div class="btn mainColor1" @click="getSms">
							<span v-if="sendingCode">{{ count }}</span>
							<span v-else>{{ $t('sPhone.getCode') }}</span>
						</div>
					</div>
			
					<div class="c_title">{{ $t('tba.PasswordInput') }}</div>
					<div class="passwordBox" style="margin-bottom: 3rem;">
						<div class="pc_in">
							<div class="divbox"><span :class="{ bl: index < password.length }" v-for="(item, index) in 6" :key="item"></span></div>
							<!-- <p><input v-model="password" @input="checkPassword" maxLength="6" id="password" class="body1StyleInputStyle" type="text" /></p> -->
							<p><input v-model="password" maxlength="6"  @input="checkPassword" id="password" class="body1StyleInputStyle" type="text" /></p>
						</div>
					</div>
				</div>
				<div class="comBtnStyle" v-if="verifyType && passwordType" @click="confirmWithdraw()">{{ $t('tba.yes') }}</div>
				<div class="comBtnStyle disabled" v-else>{{ $t('tba.yes') }}</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';
import { logout } from '@/router/index.js';
export default {
	name: 'withdrawMoney',
	components: { commonHead },
	data() {
		return {
			userInfo: '',
			username: '',
			phoneNum: '',
			codeSending: false,
			haveValue: false,
			verifyCode: '',
			password: '',

			sendingCode: false, // 获取验证码 是否发送中禁用
			count: 60, // 60s 倒计时
			timer: null, // 倒计时的 定时器
			sid: '' ,// 获取到的验证码
			// 20210415------
			dataForm: this.$route.query.dataForm,
			verifyType: false,
			passwordType: false,
			nation: 86
		};
	},
	mounted() {
		// this.prepareWithdraw();
		// -----20200411[start]-----
		this.initData();
		// -----20200411[end]-----
		console.log('dataForm', this.dataForm)
	},
	created() {
		if (window.plus) {
			this.plusReady();
		} else {
			document.addEventListener('plusready', this.plusReady, false); //
		}
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		inputVerifyCode() {
			if (this.verifyCode.length !== 6) {
				// console.log('输入不完整', this.verifyCode)
				this.verifyType = false
				return
			}
			this.dataForm.code = this.verifyCode
			this.verifyType = true
		},
		checkPassword() {
			if (this.password.length == 6) {
				document.getElementById('password').blur();
				
				this.dataForm.tradepwd = this.password
				this.passwordType = true
				return
			}
			// console.log('输入不完整', this.password)
			this.passwordType = false
		},
		// 获取用户信息-手机号+用户名
		initData() {
		    this.$post2("user/api/index/getMetaInfo", {})
		        .then((res) => {
		            this.phoneNum = res.phone;
		            this.nation = res.nation;
		        })
		        .catch((e) => {
		            this.$toast.fail(e);
		        });
		},
		
		plusReady() {
			var ws = plus.webview.currentWebview(); //pw回车可输出plus.webview
		},

		// 点击获取验证码-获取sid
		getSms() {
			if (this.sendingCode == false) {
				let url = '/security/index/phonecode';
				let parameter = {
					phone: this.phoneNum,
					nation: this.nation,
					op: 'register'
				};
				this.$post2(url, parameter)
					.then(res => {
						this.sid = res.sid;
						// this.$toast.success("验证码发送成功");
						this.$toast.success(this.$t('tba.VerificationCodeSentSuccess'));
						if (!this.timer) {
							// this.dateTwo = Date.parse(new Date());
							this.countdown();
						}
						
						// 20210415----------
						this.dataForm.sid = res.sid
						
					})
					.catch(e => {});
			}
		},
		countdown() {
			this.count = 60;
			this.sendingCode = true;
			this.timer = setInterval(() => {
				if (this.count > 1 && this.count <= 60) {
					this.count--;
				} else {
					this.sendingCode = false;
					clearInterval(this.timer);
					this.timer = null;
				}
			}, 1000);
		},

		
		// 确认提现-按钮
		confirmWithdraw() {
			// console.log('confirmWithdraw')
			// return
			this.dataForm.tradepwd = this.$SHA256(this.dataForm.tradepwd);

			this.$post2('Pay/Api/Index/directConfirmWithdraw', this.dataForm)
				.then(res => {
					console.log(res);
					// this.$toast('申请成功');
					this.$toast.success(this.$t('tixian.tansix'));
					this.dataForm.tradepwd = '';
				})
				.catch(err => {
					console.log(err);
					if(err == 'verification code fail') {
						this.$toast.fail(this.$t('tixian.yzmerr'));
					}else if(err == '交易密码错误') {
						this.$toast.fail(this.$t('tixian.pwderr'));
					}else{
						this.$toast.fail(this.$t('tixian.tberr'));
					}
					// this.$toast.fail(this.$t('tba.tibiAddressError'));
					this.dataForm.tradepwd = '';
				});
		}
	}
};
</script>

<style scoped lang="less">
.body1StyleInputStyle {
	border: 0px;
	font-size: 0.28rem;
	width: 6.7rem;
	line-height: 0.8rem;
}
.pc_in {
	width: 100%;
	position: relative;
	overflow: hidden;
	margin-top: 0.22rem;
}

.pc_in .divbox {
	display: flex;
	width: 100%;
	// justify-content: space-between;
	height: 1rem;
	overflow: hidden;
}

.pc_in .divbox span {
	// flex: 1;
	width: 1.4rem;
	height: 100%;
	// display: block;
	// float: left;
	// margin: 0 0.2rem;
	border-radius: .04rem;
	border: 1px solid #E5E5E5;
	&:first-child {
		border-top-left-radius: 0.16rem;
		border-bottom-left-radius: 0.16rem;
	}
	&:last-child {
		border-top-right-radius: 0.16rem;
		border-bottom-right-radius: 0.16rem;
	}
	// margin: 0 .08rem;
	// &:first-child {
	// 	margin-left: 0;
	// }
	// &:last-child {
	// 	margin-right: 0;
	// }
}

.pc_in .divbox span.bl {
	border-color: #5569FC;
	
}
.pc_in p {
	position: absolute;
	// width: 100%;
	width: 100%;
	height: 1rem;
	line-height: 1rem;
	top: 0;
	left: 0;
	background: none;
	overflow: hidden;
	// padding: 0 .3rem;
}

.pc_in p input {
	color: #5569FC;
	height: 1rem;
	line-height: 1rem;
	letter-spacing: 0.8rem;
	background: none;
	text-indent: 0.3rem;
	font-size: 0.64rem;
	display: block;
	width: 100%;
	
}

// ----------20200411[start]-----------
.layoutBox {
	min-height: calc(100vh - 54px);
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	font-family: PingFangSC-Regular;
}
.comBtnStyle {
	margin: 0 0.2rem;
	color: #FFFFFF;
	font-size: 0.32rem;
	text-align: center;
	height: 0.9rem;
	line-height: 0.9rem;
	background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
	border-radius: 0.16rem;
}
.securityCheck {
	padding: 0.3rem 0;
	margin: 0 0.4rem;
	font-size: .28rem;
	.top {
		display: flex;
		align-items: center;
		margin-bottom: 0.4rem;
		font-size: 0.28rem;
		color: #333333;
		.caption {
			// font-weight: bold;
			// line-height: .45rem;
		}
		.phone {
			margin-left: 0.2rem;
		}
	}
	.verifyCode {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 0.64rem;
		border-bottom: 0.5px solid rgba(211, 217, 222, 0.6);
		.inp {
			color: #999999;
			font-size: .24rem;
			display: block;
			width: 100%;
			flex: 1;
			// height: .6rem;
			padding: 10px 0;
			border: 0;
		}
		.btn {
			padding: 0.2rem 0;
			font-size: 0.24rem;
			// border: 1px solid #90C4FF;
			margin-left: .25rem;
		}
	}
	.c_title {
		color: #333333;
		font-size: 0.28rem;
		font-weight: bold;
	}
}
// ----------20200411[end]-----------
</style>
