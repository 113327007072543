<template>
	<div class="whole">
		<!-- <title-two url="/widthdrawMoneyInfo" title="安全验证" /> -->
		<common-head :title="$t('tba.SecurityVerification')" :is-return="true"></common-head>

		<div class="layoutBox bgWhite">
			<div class="securityCheck" style="padding-top: 2rem;">

				<div class="caption">{{ $t('tba.PasswordInput') }}</div>
				<div class="passwordBox" style="margin-bottom: 3rem;">
					<div class="pc_in">
						<div class="divbox"><span :class="{ bl: index < password.length }" v-for="(item, index) in 6" :key="item"></span></div>
						<!-- <p><input v-model="password" @input="checkPassword" maxLength="6" id="password" class="body1StyleInputStyle" type="text" /></p> -->
						<p><input v-model="password" @input="checkPassword" id="password" class="body1StyleInputStyle" type="text" /></p>
					</div>
				</div>
			</div>
			<div class="comBtnStyle" v-if="passwordType" @click="buyCard()">{{ $t('tba.yes') }}</div>
			<div class="comBtnStyle disabled" v-else>{{ $t('tba.yes') }}</div>
		</div>
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';
import { logout } from '@/router/index.js';
export default {
	name: 'checkClubVerifyCode',
	components: { commonHead },
	data() {
		return {
			// 20210415------
			type: this.$route.query.type,
			num: this.$route.query.num,
			password: '',
			passwordType: false,
		};
	},
	created() {
		if (window.plus) {
			this.plusReady();
		} else {
			document.addEventListener('plusready', this.plusReady, false); //
		}
	},
	methods: {
		
		checkPassword() {
			if (this.password.length == 6) {
				document.getElementById('password').blur();
				
				this.passwordType = true
				return
			}
			// console.log('输入不完整', this.password)
			this.passwordType = false
		},
		
		plusReady() {
			var ws = plus.webview.currentWebview(); //pw回车可输出plus.webview
		},

		
		// 确认购买-按钮
		buyCard() {
			let tradepwd = this.$SHA256(this.password);
			this.$post2("User/Api/Index/buyActiveCode", {
			    type: this.type,
			    tradepwd: tradepwd,
			    num: this.num,
			})
			    .then((res) => {
			        // this.$toast("购买成功！");
					this.$toast(this.$t('tba.SuccessfulPurchase'));
					this.$router.push({
						path: '/newHome'
					})
			        // on confirm
			    })
			    .catch((e) => {
			        this.$toast(e);
			        // on cancel
			    });
		}
	}
};
</script>

<style scoped lang="less">
.body1StyleInputStyle {
	border: 0px;
	font-size: 0.28rem;
	width: 80%;
	line-height: 0.8rem;
}
.pc_in {
	width: 100%;
	position: relative;
	overflow: hidden;
}

.pc_in .divbox {
	display: flex;
	width: 100%;
	justify-content: space-between;
	height: 1rem;
	overflow: hidden;
}

.pc_in .divbox span {
	// flex: 1;
	width: 1rem;
	height: 100%;
	// display: block;
	// float: left;
	// margin: 0 0.2rem;
	border-radius: .04rem;
	border: 1px solid #DDDDDD;
	// margin: 0 .08rem;
	// &:first-child {
	// 	margin-left: 0;
	// }
	// &:last-child {
	// 	margin-right: 0;
	// }
}

.pc_in .divbox span.bl {
	border-color: #90C4FF;
	
}
.pc_in p {
	position: absolute;
	// width: 100%;
	width: calc(100% + 1.5rem);
	height: 1rem;
	line-height: 1rem;
	top: 0;
	left: 0;
	background: none;
	overflow: hidden;
	// padding: 0 .3rem;
}

.pc_in p input {
	color: #90C4FF;
	height: 1rem;
	line-height: 1rem;
	letter-spacing: 0.8rem;
	background: none;
	text-indent: 0.3rem;
	font-size: 0.64rem;
	display: block;
	width: 100%;
	
}

// ----------20200411[start]-----------
.layoutBox {
	min-height: calc(100vh - 1.76rem);
	padding: 0.3rem 0.3rem 0.5rem;
}
.comBtnStyle {
	color: #ffffff;
	font-size: 0.32rem;
	text-align: center;
	height: 0.88rem;
	line-height: 0.88rem;
	background: #90c4ff;
	border-radius: 0.12rem;
}
.securityCheck {
	font-size: .28rem;
	.caption {
		font-size: .32rem;
		font-weight: bold;
		line-height: .45rem;
		margin-bottom: .25rem;
	}
	.phone {
		margin-bottom: .5rem;
	}
	.verifyCode {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: .5rem;
		.inp {
			font-size: .28rem;
			display: block;
			width: 100%;
			flex: 1;
			height: .6rem;
			border: 0;
			border-bottom: 1px solid #C8C7CC;
		}
		.btn {
			width: 1.1rem;
			height: .52rem;
			text-align: center;
			line-height: .52rem;
			border-radius: .12rem;
			border: 1px solid #90C4FF;
			margin-left: .25rem;
		}
	}
}
// ----------20200411[end]-----------
</style>
