<template>
	<div class="container">
		<!-- 安全中心 -->
		<!-- <common-head :title="$t('tba.Securitycenter')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('tba.Securitycenter')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="layoutBox bgWhite">
				<com-list :label="$t('tba.changeLoginPass')" linkUrl="setLoginPwd">
					<img slot="icon" src="../../assets/imgs/new_color/gai_mima.png" alt="" />
				</com-list>
				<com-list :label="$t('tba.setTransferPass')" linkUrl="setThePassword">
					<img slot="icon" src="../../assets/imgs/new_color/gai_jiaoyimima.png" alt="" />
				</com-list>
				<!-- <com-list :label="$t('tba.setDefaultExchange')" linkUrl="defaultExchange">
					<img slot="icon" src="../../assets/imgs/new_color/gai_jiaoyisuo.png" alt="" />
				</com-list> -->
				<com-list :label="$t('Uset.SetEmail')" linkUrl="bindEmail">
					<img slot="icon" src="../../assets/imgs/new_color/icon_email.png" alt="" />
				</com-list>
			</div>
		</div>
		
	</div>
</template>

<script>
	import commonHead from '@/components/commonHead';
	import comList from './components/list';
	export default {
		name: 'securityCenter',
		components: {
			commonHead,
			comList,
		},
		methods: {
			onClickLeft() {
				this.$router.back(-1);
			},
		},
	}
</script>

<style scoped lang="less">
	.layoutBox {
		font-family: PingFangSC-Regular;
		// margin: 0 .2rem;
		padding: 0 10px;
		margin-top: 0.2rem;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		min-height: 91.8vh;
		padding-top: 0.2rem;
	}
</style>
