<template>
  <div class="tpuschool">
    <van-nav-bar title="币种交易设置" left-arrow @click-left="onClickLeft()" />
    <div>
      <ul class="ulList">
        <li class="liStyle">
          <van-icon class="iconStyle" name="fire" />
          <img src="" alt="" /><span><strong>首单额度</strong></span
          >
          <span style="margin-left:3rem">30</span>
          <span style="margin-left: 0.3rem;">USDT</span>
        </li>
      </ul>
    </div>
    <div class="fontStyle">
      <p>按照单位、本金、交易货币计算得出首单买入金额</p>
    </div>
 <ul class="ulList">
        <li class="liStyle">
          <van-icon class="iconStyle" name="fire" />
          <img src="" alt="" /><span><strong>首单额度</strong></span
          >
          <span style="margin-left:3rem">7</span>
          <span style="margin-left: 1rem;">单</span>
        </li>
      
        <li class="liStyle">
          <van-icon class="iconStyle" name="fire" />
          <img src="" alt="" /><span><strong>首单额度</strong></span
          >
          <span style="margin-left:3rem">3</span>
          <span style="margin-left: 1rem;">单</span>
        </li>
        
      </ul>
    <!-- <div class="btnFdivStyle">
      <van-button class="btnStyle" type="info">导入</van-button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "transactionSetup",
  data() {
    return {
      popupShow: false,
      popupShow2: false,
      popupShow3: false,
      list2: {},
      ifhave: false,
      show: false,
      apiUpdate: {
        apiKey: "1",
        SecretKey: "1",
      },
    };
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.push("/singleShotDtrategy");
    },
    onCancel() {
      Toast("取消");
    },
    btnChange() {
      this.show = true;
    },
  },
};
</script>

<style lang="less" scoped>
.btnFdivStyle {
  text-align: center;
}
.btnStyle {
  margin-top: 1rem;
  width: 4.2rem;
}

.inputStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}

.fontStyle {
  font-size: 0.1rem;
  color: #9b9b9b;
  margin: 0.3rem;
}

.iconStyle {
  margin-right: 0.15rem;
}

.ulList > li {
  padding: 0.3rem;
  margin: 0.3rem;
  background: #ffffff;
  border-radius: 5px;
}

.listStyle {
  background: #ffffff;
  border-radius: 5px;
}

.roleBtnStyle {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 5px;
}

.tpuschool {
  background: #f1f1f3;
  height: 100%;
  width: 100%;
}
</style>


