<template>
	<div class="container bgVip">
		<div class="commonHead1">
			<div class="head">
				<div class="left" @click="onClickLeft">
					<div class="icon"><i class="icon_arrL"></i></div>
				</div>
				<div class="title">{{ $t('tba.invitefriends') }}</div>
				<!-- <div class="right" @click="onclickRight"><span class="text">保存海报</span></div> -->
			</div>
		</div>
		<div class="content" :style="{backgroundImage: 'url(' + url + ')'}">
			<!-- <div class="head_img1">
				<img src="../../assets/imgs/yao_head_img1.png" />
			</div>
			<div class="head_img2">
				<div class="img"><img src="../../assets/imgs/yao_head_txt.png" /></div>
			</div>
			<div class="mid_content">
				<div class="mid_item">
					<div class="img"><img src="../../assets/imgs/yao_mid_img1.png" /></div>
					<div class="mid_txt">AI追踪建仓</div>
					<div class="mid_txt">智能追踪止盈</div>
				</div>
				<div class="mid_item1">
					<div class="img"><img src="../../assets/imgs/yao_mid_img2.png" /></div>
					<div class="mid_txt">支持接入火币、币安</div>
					<div class="mid_txt">OKEX等主流平台</div>
				</div>
				<div class="mid_item">
					<div class="img"><img src="../../assets/imgs/yao_mid_img3.png" /></div>
					<div class="mid_txt">多种盈利模式</div>
					<div class="mid_txt">保障平台收益</div>
				</div>
			</div> -->
			<div class="shareFooter">
				<div class="op_container">
					<p class="yaoQingStyle">数字资产AI量化服务平台</p>
				    <p class="yaoQingStyle">邀请码 <span>{{ inviteCode }}</span></p>
				    <!-- <van-button
				            plain
				            type="info"
				            class="btn1 leftBtn copyLink"
				            :data-clipboard-text="link"
				            @click="copy"
				            size="small"
				    >
				        {{ $t("team.CopyInvitationAddress") }}
				    </van-button>
				    <van-button
				            plain
				            type="info"
				            class="btn2 btn1 rightBtn copyCode"
				            :data-clipboard-text="inviteCode"
				            @click="copy2"
				            size="small"
				    >
				        {{ $t("team.CopyInvitationCode") }}
				    </van-button> -->
				</div>
				<div class="codeImgStyle">
					<div id="share_page_qrcode" style="width: 100%;height: 100%" ref="share_page_qrcode"></div>
				</div>
			</div>
		</div>
		
		<div style="display: none">
		    <van-field
		            v-model="link"
		            :name="$t('team.InviteLink')"
		            :label="$t('team.InviteLink')"
		            :placeholder="$t('team.InviteLink')"
		    />
		    <van-field
		            v-model="inviteCode"
		            :name="$t('team.InviteCode')"
		            :label="$t('team.InviteCode')"
		            :placeholder="$t('team.InviteCode')"
		    />
		</div>
		
	</div>
</template>

<script>
import Clipboard from 'clipboard';
// 地址转换二维码插件
import QRCode from 'qrcodejs2';
import commonHead from '@/components/commonHead';
export default {
	name: 'sharePage',
	components: { commonHead },
	data() {
		return {
			msg: '<',
			inviteCode: '',
			link: '',
			hasCode: 0,
			// 20200410----
			isShowPoster: false,
			url: '../../assets/imgs/yaoqing_haibao.png'
			// 20210415-----
			
		};
	},
	mounted() {
		this.getInviteCode();
	},
	created() {
		this.getPictureList();
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		onclickRight() {
			// this.$router.push({
			// 	path: '/assets',
			// 	query: {
			// 	}
			// });
		},
		// ------20200410[start]----
		showPoster() {
			this.isShowPoster = true
		},
		closePoster () {
			this.isShowPoster = false
		},
		// ------20200410[end]----
		gotoMember() {
			this.$router.push({
				path: '/memberClub'
			});
		},
		// 复制-邀请地址
		copy() {
			var clipboard = new Clipboard('.copyLink');
			clipboard.on('success', e => {
				this.$toast(this.$t('xinzeng.fuzhichengg'));
				// 释放内存
				clipboard.destroy();
			});
		},
		// 复制-邀请码
		copy2() {
			var clipboard = new Clipboard('.copyCode');
			clipboard.on('success', e => {
				this.$toast(this.$t('xinzeng.fuzhichengg'));
				// 释放内存
				clipboard.destroy();
			});
		},
		qrcode(address) {
			// console.log(address)
			console.log(address, 1111);

			new QRCode('share_page_qrcode', {
				width: 100, // 设置宽度，单位像素
				height: 100, // 设置高度，单位像素
				text: address // 设置二维码内容或跳转地址
			});
		},

		backToIndex() {
			this.$router.push('geren');
		},
		getInviteCode() {
			this.$post2('User/Api/Index/getMyInviteCode', {})
				.then(res => {
					this.inviteCode = res.refcode;
					this.link = res.link;
					this.hasCode = 1;
					//
					// document.getElementById("qrcode").innerHTML = "";

					this.$nextTick(() => {
						this.qrcode(res.link);
					});
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		},
		//获取背景图
		getPictureList() {
			this.$post2('User/Api/Passport/getPictureList', {})
				.then(res => {
					if(res.invite_poster_url) {
						this.url = res.invite_poster_url;
					}
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		}
	}
};
</script>

<style lang="less">
#share_page_qrcode {
	img,
	canvas {
		display: block;
		width: 100%;
		height: 100%;
	}
}
</style>
<style lang="less" scoped>
.btn1,
.btn2 {
	color: #000;
	border: 0;
	font-size: 14px;
	background: none;
	padding: 0;
	margin-right: 0.36rem;
}

/*.yaoQingStyle {*/
/* position: absolute;
            bottom: 1.2rem;
            left: 0rem auto; */
/*margin-top: 11rem;*/
/*}*/
.commonHead1 {
	background-color: #ffffff;
	// padding: 0 .3rem;
	.head {
		position: relative;
		height: 0.88rem;
		line-height: 0.88rem;
		background-color: #ffffff !important;
		padding: 0 0.3rem;
	}
	.left {
		position: absolute;
		left: 0.3rem;
		top: 0;
		.icon {
			width: 0.6rem;
			height: 0.88rem;
			display: flex;
			justify-content: center;
			align-items: center;
			.icon_arrL {
				border-top: 0.04rem solid #000;
				border-left: 0.04rem solid #000;
			}
			img {
				width: 0.4rem;
				height: 0.4rem;
			}
		}
	}
	.title {
		color: #333333;
		font-size: 0.32rem;
		font-weight: bold;
		text-align: center;
		text-transform: capitalize;
	}
	.right {
		position: absolute;
		right: 0.3rem;
		top: 0;
		height: 0.88rem;
		display: flex;
		align-items: center;
		.text {
			color: #6f7a8b;
			font-size: 0.28rem;
		}
		img {
			width: 0.4rem;
			height: 0.4rem;
		}
	}
}
.titlePStyle {
	/* margin-bottom: 0.2rem; */
	margin-top: 0.2rem;
	/* padding-top: 0.1rem; */
	margin-right: 7rem;
	transform: rotate(180deg);
}

.qrcode {
	width: 0.3rem;
}

.fengXiangCode {
	position: absolute;
	bottom: 6.5rem;
	left: 3.2rem;
}

.spanStyle {
	margin-right: 7rem;
}

.head_img1 {
	position: absolute;
	top: 0.85rem;
	left: 1.95rem;
	width: 3.66rem;
	height: 1.53rem;
	img {
		width: 100%;
		height: 100%;
	}
}

.head_img2 {
	position: absolute;
	top: 2.87rem;
	left: 2.06rem;
	width: 3.39rem;
	height: 0.5rem;
	line-height: 0.5rem;
	background-color: #FFFFFF;
	border-radius: 0.25rem;
	.img {
		margin: 0 auto;
		width: 2.92rem;
		height: 0.27rem;
		img {
			width: 100%;
			height: 100%;
		}
	}
}

.mid_content {
	position: absolute;
	left: 0.7rem;
	right: 0.7rem;
	top: 8.8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.mid_item {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 1.44rem;
		.img {
			width: 0.44rem;
			height: 0.44rem;
			margin-bottom: 0.26rem;
			img {
				height: 100%;
				width: 100%;
			}
		}
		.mid_txt {
			text-align: center;
			font-size: 0.24rem;
			color: #FFFFFF;
		}
	}
	.mid_item1 {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 2.16rem;
		.img {
			margin-bottom: 0.26rem;
			width: 0.44rem;
			height: 0.44rem;
			img {
				height: 100%;
				width: 100%;
			}
		}
		.mid_txt {
			text-align: center;
			font-size: 0.24rem;
			color: #FFFFFF;
		}
	}
}

.op_container {
	flex: 1;
	text-align: left;
	// margin-left: 0.5rem;
}

.container {
	// background-image: url('@/assets/imgs/share/backGroundImg2.png');
	background-position: center top;
	background-size: 100% 100%;
	font-family: PingFangSC-Regular;
	// height: 100%;
	// width: 100%;
	// color: white;
	// text-align: center;
	&.bgVip {
		
	}
	
	.content {
		height: calc(100vh - 44px);
		// background-image: url('../../assets/imgs/yaoqing_haibao.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
	}
	
	.shareFooter {
		position: absolute;
		bottom: 0.89rem;
		left: 0;
		right: 0;
		margin: 0 0.4rem;
		display: flex;
		align-items: center;
		// background: linear-gradient(180deg, #fefeff 0%, #e6e6ff 100%);
		
		.code {
			width: 1.29rem;
			height: 1.29rem;
			margin: 0 auto;
		}

		.codeImgStyle {
			width: 1.42rem;
			height: 1.42rem;
			border: 3px solid #FFFFFF;
			img {
				width: 100%;
				height: 100%;
			}
		}

		.yaoQingStyle {
			color: #FFFFFF;
			font-size: 0.32rem;
			span{
				font-family: PingFangSC-Medium;
				font-weight: bold;
			}
			&:last-child {
				margin-top: 0.52rem;
			}
			// margin: 0.15rem 0 0.2rem;
		}

		.rightBtn {
			margin-right: 10px;
		}
	}
}

.footerBtn {
	color: #ffffff;
	font-size: 0.58rem;
	width: 4.24rem;
	height: 1.54rem;
	padding-bottom: 0.14rem;
	border-radius: 0.77rem;
	background: #ff4b04 url(../../assets/imgs/share/VIP.png);
	background-size: 100% 100%;
	margin: 0 auto;
	span {
		font-weight: bold;
	}
}

// --------20200410[start]----------
.layoutBox {
	padding: .1rem .3rem .5rem;
	.shareAds {
		margin-bottom: .1rem;
		img {
			display: block;
			width: 100%;
		}
	}
	.shareSteps {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: .5rem .25rem;
		background: #FFFFFF;
		margin-bottom: .1rem;
		.item {
			font-size: .28rem;
			text-align: center;
			.icon {
				margin-bottom: .1rem;
				img {
					width: .64rem;
					height: .64rem;
					display: block;
					margin: 0 auto;
				}
			}
			p {
				width: 4em;
				&.mtext {
					width: 6em;
				}
			}
		}
		.split {
			width: .32rem;
			height: .3rem;
			background: url(../../assets/imgs/share/arr.png) no-repeat center center;
			background-size: 100% 100%;
		}
	}
	.shareInfo {
		background: #FFFFFF;
		border-radius: .12rem;
		margin-bottom: .1rem;
		.top {
			padding: .25rem;
			border-bottom: 1px solid #EDEDED;
		}
		.bottom {
			padding: .25rem;
			display: flex;
			justify-content: space-between;
			.btn {
				color: white;
				font-size: .32rem;
				text-align: center;
				width: 3.25rem;
				height: .88rem;
				line-height: .88rem;
				background: #93C4FC;
				border-radius: .12rem;
				&.btnR {
					background: #E0C3FC;
					position: relative;
					&::before {
						content: '';
						position: absolute;
						left: -.15rem;
						width: .3rem;
						height: 100%;
						background: #FFFFFF;
						transform: skewX(-15deg);
						transform-origin: 50% 50%;
					}
				}
				
			}
		}
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: .1rem;
			&:last-child {
				margin-bottom: 0;
			}
			.inp {
				// width: 1.8rem;
				height: .7rem;
				line-height: .7rem;
				padding: 0 .1rem 0 .35rem;
				background: #F6F7F9;
				border-radius: .34rem;
				display: flex;
				justify-content: space-between;
				.link {
					width: 3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.copy {
				width: .7rem;
				height: .7rem;
				background: url(../../assets/imgs/share/invitation_icon_file.png) no-repeat center center;
				background-size: .4rem .4rem;
				border: 0;
			}
		}
		
	}
	.shareReward {
		padding: .25rem;
		background: #FFFFFF;
		border-radius: .12rem;
		.caption {
			height: .5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: .15rem;
			h3 {
				font-size: .32rem;
			}
		}
	}
}
.posterLayout {
	position: fixed;
	z-index: 98;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 4, 15, 0.4);
}
.posterCode {
	position: fixed;
	z-index: 99;
	left: .3rem;
	right: .3rem;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	.poster {
		position: relative;
		img {
			display: block;
			width: 100%;
		}
		.code {
			position: absolute;
			left: 50%;
			top: 2.9rem;
			width: 1.8rem;
			height: 1.8rem;
			margin-left: -.9rem;
		}
	}
	.close {
		width: .8rem;
		height: .8rem;
		background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
		background-size: .6rem .6rem;
		margin: .5rem auto 0;
	}
}

// --------20200410[start]----------
</style>
