<template>
	<div class="container">
		<!-- 免责条款 -->
		<common-head :is-return="true" :title="title"></common-head>

		<div class="layoutBox bgWhite">
			<div class="article">
				<div class="content" v-for="(item, index) in articleList" :key="index">
					<h4><strong>{{ item.title }}</strong></h4>
					<div v-for="(subitem, subindex) in item.content" :key="subindex">
						<h5 v-if="subitem.title"><strong>{{ subitem.title }}</strong></h5>
						<!-- <p v-else>{{ subitem.content }}</p> -->
						<div v-if="subitem.content.length" v-for="(subitemchild, subindexchild) in subitem.content" :key="subindexchild">
							<p>{{ subitemchild.title }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';
import userLaw_zh from './userLaw1.js'
import userLaw_zhf from './userLaw1.js'
import userLaw_en from './userLaw1.js'
export default {
	name: 'userLaw',
	components: { commonHead },
	data() {
		return {
			title: '',
			articleList: [],
		}
	},
	created() {
		this.initData()
	},
	methods: {
		initData() {
			let lang = localStorage.getItem('lang')
			let userLaw = {}
			if (lang == 'zh') {
				userLaw = userLaw_zh.userLaw_zh
				// console.log(userLaw.title, userLaw.content)
			}
			if (lang == 'zhf') {
				userLaw = userLaw_zhf.userLaw_zhf
				// console.log(userLaw.title, userLaw.content)
			}
			if (lang == 'en') {
				userLaw = userLaw_en.userLaw_en
				// console.log(userLaw.title, userLaw.content)
			}
			this.title = userLaw.title
			this.articleList = userLaw.content
		},
	},
};
</script>

<style scoped lang="less">
.layoutBox {
	padding: 0.3rem;
	min-height: calc(100vh - 1.76rem);
}
.article {
	color: #666666;
	font-size: .24rem;
	line-height: 1.5;
	h4 {
		color: #373737;
		font-size: .28rem;
		margin-bottom: .2rem;
	}
	h5 {
		color: #373737;
		font-size: .24rem;
		margin-bottom: .1rem;
	}
	p {
		margin-bottom: .1rem;
	}
	div {
		margin-bottom: .2rem;
	}
}
</style>
