<template>
    <!-- <div class="titleTwo" :class="{back: back}"> -->
    <div class="titleTwo">
        <div class="return" v-if="isReturn" @click="onClickLeft">
            <img src="../assets/imgs/icon/return-white.png" v-if="back">
            <img src="../assets/imgs/icon/return.png" v-else>
        </div>
        <!-- <p style="font-size: 0.32rem" :class="{wordBold: !isReturn}">{{title}}</p> -->
        <p>{{title}}</p>
        <div class="rightIco">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            back: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            url: {
                type: String,
                default: '/'
            },
            isReturn: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            onClickLeft() {
                if (this.url && this.url !== '/') {
                    this.$router.push(this.url);
                } else {
                    this.$router.back(-1);
                }
            },
        }
    }
</script>
<style scoped lang="less">
    .titleTwo {
        background: #fff;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        position: relative;

        p{
            position: relative;
            z-index: 99;
        }


        .return {
            height: 1rem;
            width: 1rem;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 999;

            img {
                display: inline-block;
                width: 0.27rem;
            }
        }
        .rightIco{
            height: 1rem;
            width: 1rem;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 999;

        }

        .wordBold{
            font-weight: bold;
        }

    }
    .titleTwo.back {
        background: none;
        z-index: 11;
        p{
            color: #fff;
        }
    }

</style>
