import { render, staticRenderFns } from "./viewArticle.vue?vue&type=template&id=4e23f29a&scoped=true&"
import script from "./viewArticle.vue?vue&type=script&lang=js&"
export * from "./viewArticle.vue?vue&type=script&lang=js&"
import style0 from "./viewArticle.vue?vue&type=style&index=0&id=4e23f29a&lang=less&scoped=true&"
import style1 from "./viewArticle.vue?vue&type=style&index=1&id=4e23f29a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e23f29a",
  null
  
)

export default component.exports