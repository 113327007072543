<template>
	<div class="log-list-item">
		<div class="hd">
			<div class="caption">
				<span class="yen">{{ list.currency_pair }}</span>
				<span class="specialFont">{{ list.time }}</span>
			</div>
		</div>
		<div class="bd">
			<div class="info">
				<div>ID:</div>
				<div class="specialFont">{{ list.id }}</div>
			</div>
			<div class="info1">
				<div class="i_left">记录:</div>
				<div class="specialFont1">{{ list.content }}</div>
			</div>
			<!-- <span>{{ $t('myPledge.operate') }}: </span> -->
			<!-- <div class="remarks">{{ list.content }}</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'logList',
		props: {
			list: {
				type: Object,
				default: () => {},
			},
			exchange: {
				type: String,
				default: '',
			}
		},
		data() {
			return {
				showContent: false,
			}
		},
		methods: {
			handleToggle() {
				this.showContent = !this.showContent
			},
		},
	}
</script>

<style scoped lang="less">
	
	.log-list-item {
		color: #666666;
		font-size: .28rem;
		// background: #F5F5F5;
		background: #FFFFFF;
		border-radius: 0.16rem;
		margin-bottom: 0.25rem;
		.hd {
			margin: 0 .2rem;
			border-bottom: 0.5px solid #D3D9DE;
			.caption {
				padding: 0.2rem 0;
				// height: .4rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				// margin-bottom: .25rem;
				.yen {
					color: #333333;
					font-size: 0.32rem;
					margin-right: .25rem;
				}
				.type {
					font-size: .24rem;
				}
			}
			.specialFont {
				color: #666666;
				font-size: 0.24rem;
				text-transform: uppercase;
			}
			
		}
		.bd {
			padding: 0.1rem 0;
			margin: 0 .2rem;
			.info {
				padding: 0.1rem 0;
				display: flex;
				align-items: center;
				font-size: 0.28rem;
				color: #666666;
				.specialFont {
					margin-left: 0.2rem;
					color: #333333;
				}
			}
			.info1 {
				padding: 0.1rem 0;
				display: flex;
				font-size: 0.28rem;
				.i_left {
					width: 17%;
					color: #666666;
				}
				.specialFont1 {
					width: 83%;
					// margin-left: 0.2rem;
					color: #333333;
				}
			}
			.remarks {
				flex: 1;
				color: #373737;
			}
		}
		.fd {
			height: .5rem;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			&::before {
				content: '';
				position: absolute;
				z-index: 1;
				left: 0;
				right: 0;
				top: 50%;
				border-top: 1px solid #D8D8D8;
			}
			img {
				width: .24rem;
				height: .24rem;
				display: block;
				background: #F5F5F5;
				position: relative;
				z-index: 2;
				&.up {
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
		}
		
	}
</style>
