const userLaw_zh = {
	title: '用户协议',
	content: [{
		// 免责声明
		title: '免责声明',
		content: [{
			title: '本产品是一款辅助交易的工具类产品，如果您是位于以下国家/地区的公民或居民，在以下国家/地区设立或以其他方式成立，则不得访问或使用本服务或交易平台：（i）美国，加拿大安大略省，加拿大魁北克省，中华人民共和国大陆,  中华人民共和国香港/澳门/台湾地区，塞舌尔共和国，百慕大，布隆迪，中非共和国，刚果民主共和国，厄立特里亚，几内亚比绍，利比亚，马里，巴勒斯坦，索马里，南苏丹，西撒哈拉，也门，古巴，克里米亚和塞瓦斯托波尔，伊朗，叙利亚，朝鲜或苏丹；(ii) 根据您的适用法律（由于您的国籍，住所，公民身份，居住地或其他原因）访问或使用服务或交易平台是非法的司法管辖区。用户本人应在确保完全理解本协议全部条款基础上审慎决定,一经勾选选择表示用户完全同意则本协议生效。',
			content: []
		},{
			title: '1.免责声明',
			content: [
				{
					title: '（1）除非我方以书面形式明确提供相反的内容，否则我方服务是在“当前规定的”和“可行的”基础上提供的。在法律允许的范围内，我方明确否认，且贵方明确放弃，任何形式的明示或默示担保，包括但不限于适销性、特定用途适用性、所有权和不侵犯我方服务，包括其中包含的信息、内容和材料。'
				},
				{
					title: '（2）贵方承认，由于各种原因，包括软件故障、第三方提供商的协议变更、互联网中断、不可抗力事件或其他灾难（包括第三方分布式拒绝服务攻击、计划内或计划外维护，或我方控制范围内外的其他原因），贵方通过我方服务存储或传输的信息可能会丢失、损坏或暂时不可用。贵方全权负责备份和维护通过我方服务存储或传输的任何信息的副本。部分司法管辖区不允许对与消费者签订的合同中默示条款的免责声明，因此本条中的部分或全部免责声明可能不适用于贵方。'
				}
			]
		}, {
			title: '2. 责任范围',
			content: [
				{
					title: '（1）除法律另有规定外，在任何情况下，本产品、我方董事、高级职员、成员、雇员、代理人或承包商均不对任何特殊、间接或结果性损害或任何类型的其他损害负责，包括但不限于：使用损失、利润损失或数据损失，不论是在合同诉讼、侵权行为（包括但不限于疏忽）中，还是因使用或不能使用我方服务或本产品材料而引起的，或以任何方式与之相关的，包括但不限于任何用户对从本产品获得的任何信息的依赖所造成或导致的任何损害，或者错误、遗漏、中断、删除文件或电子邮件所造成的损害，错误、缺陷、病毒、操作或传输延迟或任何性能故障（不论是否由于不可抗力事件导致）、通信故障、盗窃、破坏或未经授权访问本产品的记录、项目或服务所造成的损害。部分司法管辖区不允许排除某些担保或限制或排除意外或间接损害赔偿责任。因此，本条的某些限制可能不适用于贵方。'
				},
				{
					title: '（2）在适用法律允许的最大范围内，不论在合同、担保中，由使用或不能使用本产品服务或此类条款引起或与之相关的侵权行为（包括疏忽，不论是主动的、被动的还是归责的）、产品责任、严格责任或其他理论在任何情况下均不得超出贵方在引起此类责任的任何索赔日期前12个月内向本产品支付的费用。'
				},
				{
					title: '（3）我方对贵方可能向服务用户私下购买或出售的数字资产的交付、质量、安全、合法性或任何其他方面没有控制权或责任。我方不负责确保与贵方私下交易的第三方买方或卖方将完成交易、交易或被授权完成交易或交易活动。如果贵方遇到从使用服务的用户处购买或出售给该用户的数字资产或服务的问题，或者如果贵方与该用户有争议，贵方应该直接与该用户解决争议。如果贵方认为用户的行为欺诈、误导或不当，或者贵方无法充分解决与第三方的争议，如果贵方有任何问题，贵方可以联系本产品在线客服寻求帮助。'
				},
				{
					title: '（4）贵方承认，没有建立担保基金或其他安排来弥补或补偿贵方由于服务的任何其他用户违约或破产而遭受的任何金钱损失。'
				}
			]
		}]
	}, 
	// 风险声明
	{
		title: '风险声明',
		content: [
			{
				content: '1.在使用我方服务进行交易或交易活动之前，贵方极有必要了解其中风险。我方在下文更详细地介绍了使用我方服务进行交易或交易活动的潜在风险。贵方应该注意到，下文描述的风险并不是详尽无遗的，也不是按任何假定的优先级顺序呈现的。我方要求贵方仔细阅读，并考虑针对本产品上的交易和交易活动是否适合贵方的特殊情况。'
			},
			{
				content: '2.如果贵方不完全了解使用我方服务的风险，贵方应该寻求独立的专业建议。'
			},
			{
				content: '3.本文中使用的大写术语具有使用术语中列出的含义，除非上下文另有要求'
			},
			{
				title: '（1）交易风险。',
				content: [
					{
						title: 'a.贵方承认并同意，贵方应自费访问和使用我方服务。交易数字资产对的损失风险可能很大。因此，根据贵方的环境、目标和财力，贵方应该仔细考虑这种交易是否适合贵方。贵方应该了解以下几点:'
					},
					{
						title: 'i.贵方可能会蒙受转移到贵方本产品账户的资金和贵方本产品账户的所有资金的全部损失。数字资产的交易价格会在短时间内大幅波动。数字资产的交易价格也可能由于各种因素而降低，包括发现不法行为、市场操纵、数字资产属性的改变、攻击，本产品或外部账户提供商或其他服务提供商暂停或停止支持数字资产，以及本产品无法控制的其他因素。'
					},
					{
						title: 'ii.在某些市场条件下，贵方可能会发现很难或无法购买或出售数字资产。例如，如果市场流动性不足，就会出现这种情况。我方不对任何数字资产的可转让性、流动性和/或可用性负责。'
					},
					{
						title: 'iii.本产品可自行决定在任何时候暂停交易或停止提供与任何数字资产相关的服务。'
					},
					{
						title: 'iv.当然，这份简短的声明不能披露与这些交易相关的所有风险和其他方面的风险。贵方全权负责确保贵方理解并接受与数字资产交易和交易活动相关的所有风险，并且能够并愿意接受和承担此类交易和数字资产交易可能产生的所有损失。'
					}
				]
			},
			{
				title: '（2）互联网传输风险和纠错。',
				content: [
					{
					title: 'a.贵方承认使用基于互联网的交易系统存在风险，包括但不限于硬件、软件和互联网连接的故障。贵方承认，本产品不对贵方在通过我方服务进行交易时可能遇到的任何通信故障、中断、错误、扭曲或延迟负责，不论是何种原因造成的。'
				}]
			},
			{
				title: '（3）与攻击服务或本产品账户相关的风险。',
				content: [
					{
					title: 'a.我方服务和/或本产品账户及其持有的资金可能会受到安全、完整性或运营方面的攻击，贵方承认并同意本产品不对由此造成的任何损失负责。'
				}]
			},
			{
				title: '（4）监管风险。',
				content: [
					{
					title: 'a.在许多司法管辖区，数字资产的监管状况不清楚或不稳定。很难预测政府当局将如何或是否监管数字资产。同样，难以预测任何政府机构如何或是否会对现有法律、法规和/或规则做出影响任何数字资产的改变。因此，数字资产可以是有价证券，也可以根据某些司法管辖区的法律进行监管。如果政府行为使继续就任何数字资产提供服务成为非法或商业上不可取的行为，本产品可能会停止就任何数字资产提供服务，或者禁止在某些管辖区内或从某些管辖区使用我方服务。根据使用规定，如果贵国的任何适用法律禁止贵方按照使用条款使用我方服务，贵方也表示并保证贵方不会使用我方服务。'
				}]
			}
		]
	}]
}

const userLaw_zhf = {
	title: '用戶協議',
	content: [{
		// 免责声明
		title: '免責聲明',
		content: [{
			title: '本產品是一款輔助交易的工具類產品，如果您是位於以下國家/地區的公民或居民，在以下國家/地區設立或以其他管道成立，則不得訪問或使用本服務或交易平臺：（i）美國，加拿大安大略省，加拿大魁北克省，中華人民共和國大陸，中華人民共和國香港/澳門/臺灣地區，塞舌耳共和國，百慕大，布隆迪，中非共和國，剛果民主共和國，厄利垂亞，幾內亞·比索，利比亞，馬利，巴勒斯坦，索馬利亞，南蘇丹，西撒哈拉，葉門，古巴，克裡米亞和塞瓦斯托波爾，伊朗，敘利亞，朝鮮或蘇丹；（ii）根據您的適用法律（由於您的國籍，住所，公民身份，居住地或其他原因）訪問或使用服務或交易平臺是非法的司法管轄區。用戶本人應在確保完全理解本協定全部條款基礎上審慎决定，一經勾選選擇表示用戶完全同意則本協定生效。',
			content: []
		},{
			title: '1.免責聲明',
			content: [
				{
					title: '（1）除非我方以書面形式明確提供相反的內容，否則我方服務是在“當前規定的”和“可行的”基礎上提供的。在法律允許的範圍內，我方明確否認，且貴方明確放棄，任何形式的明示或默示擔保，包括但不限於適銷性、特定用途適用性、所有權和不侵犯我方服務，包括其中包含的信息、內容和材料。'
				},
				{
					title: '（2）貴方承認，由於各種原因，包括軟件故障、第三方提供商的協議變更、互聯網中斷、不可抗力事件或其他災難（包括第三方分布式拒絕服務攻擊、計劃內或計劃外維護，或我方控制範圍內外的其他原因），貴方通過我方服務存儲或傳輸的信息可能會丟失、損壞或暫時不可用。貴方全權負責備份和維護通過我方服務存儲或傳輸的任何信息的副本。部分司法管轄區不允許對與消費者簽訂的合同中默示條款的免責聲明，因此本條中的部分或全部免責聲明可能不適用於貴方。'
				}
			]
		}, {
			title: '2. 責任範圍',
			content: [
				{
					title: '（1）除法律另有規定外，在任何情況下，本產品、我方董事、高級職員、成員、雇員、代理人或承包商均不對任何特殊、間接或結果性損害或任何類型的其他損害負責，包括但不限於：使用損失、利潤損失或數據損失，不論是在合同訴訟、侵權行為（包括但不限於疏忽）中，還是因使用或不能使用我方服務或本產品材料而引起的，或以任何方式與之相關的，包括但不限於任何用戶對從本產品獲得的任何信息的依賴所造成或導致的任何損害，或者錯誤、遺漏、中斷、刪除文件或電子郵件所造成的損害，錯誤、缺陷、病毒、操作或傳輸延遲或任何性能故障（不論是否由於不可抗力事件導致）、通信故障、盜竊、破壞或未經授權訪問本產品的記錄、項目或服務所造成的損害。部分司法管轄區不允許排除某些擔保或限制或排除意外或間接損害賠償責任。因此，本條的某些限制可能不適用於貴方。'
				},
				{
					title: '（2）在適用法律允許的最大範圍內，不論在合同、擔保中，由使用或不能使用本產品服務或此類條款引起或與之相關的侵權行為（包括疏忽，不論是主動的、被動的還是歸責的）、產品責任、嚴格責任或其他理論在任何情況下均不得超出貴方在引起此類責任的任何索賠日期前12個月內向本產品支付的費用。'
				},
				{
					title: '（3）我方對貴方可能向服務用戶私下購買或出售的數字資產的交付、質量、安全、合法性或任何其他方面沒有控制權或責任。我方不負責確保與貴方私下交易的第三方買方或賣方將完成交易、交易或被授權完成交易或交易活動。如果貴方遇到從使用服務的用戶處購買或出售給該用戶的數字資產或服務的問題，或者如果貴方與該用戶有爭議，貴方應該直接與該用戶解決爭議。如果貴方認為用戶的行為欺詐、誤導或不當，或者貴方無法充分解決與第三方的爭議，如果貴方有任何問題，貴方可以聯系本產品在線客服尋求幫助。'
				},
				{
					title: '（4）貴方承認，沒有建立擔保基金或其他安排來彌補或補償貴方由於服務的任何其他用戶違約或破產而遭受的任何金錢損失。'
				}
			]
		}]
	}, 
	// 风险声明
	{
		title: '風險聲明',
		content: [
			{
				content: '1.在使用我方服務進行交易或交易活動之前，貴方極有必要了解其中風險。我方在下文更詳細地介紹了使用我方服務進行交易或交易活動的潛在風險。貴方應該註意到，下文描述的風險並不是詳盡無遺的，也不是按任何假定的優先級順序呈現的。我方要求貴方仔細閱讀，並考慮針對本產品上的交易和交易活動是否適合貴方的特殊情況。'
			},
			{
				content: '2.如果貴方不完全了解使用我方服務的風險，貴方應該尋求獨立的專業建議。'
			},
			{
				content: '3.本文中使用的大寫術語具有使用術語中列出的含義，除非上下文另有要求'
			},
			{
				title: '（1）交易風險。',
				content: [
					{
						title: 'a.貴方承認並同意，貴方應自費訪問和使用我方服務。交易數字資產對的損失風險可能很大。因此，根據貴方的環境、目標和財力，貴方應該仔細考慮這種交易是否適合貴方。貴方應該了解以下幾點:'
					},
					{
						title: 'i.貴方可能會蒙受轉移到貴方本產品賬戶的資金和貴方本產品賬戶的所有資金的全部損失。數字資產的交易價格會在短時間內大幅波動。數字資產的交易價格也可能由於各種因素而降低，包括發現不法行為、市場操縱、數字資產屬性的改變、攻擊，本產品或外部賬戶提供商或其他服務提供商暫停或停止支持數字資產，以及本產品無法控制的其他因素。'
					},
					{
						title: 'ii.在某些市場條件下，貴方可能會發現很難或無法購買或出售數字資產。例如，如果市場流動性不足，就會出現這種情況。我方不對任何數字資產的可轉讓性、流動性和/或可用性負責。'
					},
					{
						title: 'iii.本產品可自行決定在任何時候暫停交易或停止提供與任何數字資產相關的服務。'
					},
					{
						title: 'iv.當然，這份簡短的聲明不能披露與這些交易相關的所有風險和其他方面的風險。貴方全權負責確保貴方理解並接受與數字資產交易和交易活動相關的所有風險，並且能夠並願意接受和承擔此類交易和數字資產交易可能產生的所有損失。'
					}
				]
			},
			{
				title: '（2）互聯網傳輸風險和糾錯。',
				content: [
					{
					title: 'a.貴方承認使用基於互聯網的交易系統存在風險，包括但不限於硬件、軟件和互聯網連接的故障。貴方承認，本產品不對貴方在通過我方服務進行交易時可能遇到的任何通信故障、中斷、錯誤、扭曲或延遲負責，不論是何種原因造成的。'
				}]
			},
			{
				title: '（3）與攻擊服務或本產品賬戶相關的風險。',
				content: [
					{
					title: 'a.我方服務和/或本產品賬戶及其持有的資金可能會受到安全、完整性或運營方面的攻擊，貴方承認並同意本產品不對由此造成的任何損失負責。'
				}]
			},
			{
				title: '（4）監管風險。',
				content: [
					{
					title: 'a.在許多司法管轄區，數字資產的監管狀況不清楚或不穩定。很難預測政府當局將如何或是否監管數字資產。同樣，難以預測任何政府機構如何或是否會對現有法律、法規和/或規則做出影響任何數字資產的改變。因此，數字資產可以是有價證券，也可以根據某些司法管轄區的法律進行監管。如果政府行為使繼續就任何數字資產提供服務成為非法或商業上不可取的行為，本產品可能會停止就任何數字資產提供服務，或者禁止在某些管轄區內或從某些管轄區使用我方服務。根據使用規定，如果貴國的任何適用法律禁止貴方按照使用條款使用我方服務，貴方也表示並保證貴方不會使用我方服務。'
				}]
			}
		]
	}]
}

const userLaw_en = {
	title: '用户协议-英文',
	content: [{
		// 免责声明
		title: '免责声明',
		content: [{
			title: '本产品是一款辅助交易的工具类产品，如果您是位于以下国家/地区的公民或居民，在以下国家/地区设立或以其他方式成立，则不得访问或使用本服务或交易平台：（i）美国，加拿大安大略省，加拿大魁北克省，中华人民共和国大陆,  中华人民共和国香港/澳门/台湾地区，塞舌尔共和国，百慕大，布隆迪，中非共和国，刚果民主共和国，厄立特里亚，几内亚比绍，利比亚，马里，巴勒斯坦，索马里，南苏丹，西撒哈拉，也门，古巴，克里米亚和塞瓦斯托波尔，伊朗，叙利亚，朝鲜或苏丹；(ii) 根据您的适用法律（由于您的国籍，住所，公民身份，居住地或其他原因）访问或使用服务或交易平台是非法的司法管辖区。用户本人应在确保完全理解本协议全部条款基础上审慎决定,一经勾选选择表示用户完全同意则本协议生效。',
			content: []
		},{
			title: '1.免责声明',
			content: [
				{
					title: '（1）除非我方以书面形式明确提供相反的内容，否则我方服务是在“当前规定的”和“可行的”基础上提供的。在法律允许的范围内，我方明确否认，且贵方明确放弃，任何形式的明示或默示担保，包括但不限于适销性、特定用途适用性、所有权和不侵犯我方服务，包括其中包含的信息、内容和材料。'
				},
				{
					title: '（2）贵方承认，由于各种原因，包括软件故障、第三方提供商的协议变更、互联网中断、不可抗力事件或其他灾难（包括第三方分布式拒绝服务攻击、计划内或计划外维护，或我方控制范围内外的其他原因），贵方通过我方服务存储或传输的信息可能会丢失、损坏或暂时不可用。贵方全权负责备份和维护通过我方服务存储或传输的任何信息的副本。部分司法管辖区不允许对与消费者签订的合同中默示条款的免责声明，因此本条中的部分或全部免责声明可能不适用于贵方。'
				}
			]
		}, {
			title: '2. 责任范围',
			content: [
				{
					title: '（1）除法律另有规定外，在任何情况下，本产品、我方董事、高级职员、成员、雇员、代理人或承包商均不对任何特殊、间接或结果性损害或任何类型的其他损害负责，包括但不限于：使用损失、利润损失或数据损失，不论是在合同诉讼、侵权行为（包括但不限于疏忽）中，还是因使用或不能使用我方服务或本产品材料而引起的，或以任何方式与之相关的，包括但不限于任何用户对从本产品获得的任何信息的依赖所造成或导致的任何损害，或者错误、遗漏、中断、删除文件或电子邮件所造成的损害，错误、缺陷、病毒、操作或传输延迟或任何性能故障（不论是否由于不可抗力事件导致）、通信故障、盗窃、破坏或未经授权访问本产品的记录、项目或服务所造成的损害。部分司法管辖区不允许排除某些担保或限制或排除意外或间接损害赔偿责任。因此，本条的某些限制可能不适用于贵方。'
				},
				{
					title: '（2）在适用法律允许的最大范围内，不论在合同、担保中，由使用或不能使用本产品服务或此类条款引起或与之相关的侵权行为（包括疏忽，不论是主动的、被动的还是归责的）、产品责任、严格责任或其他理论在任何情况下均不得超出贵方在引起此类责任的任何索赔日期前12个月内向本产品支付的费用。'
				},
				{
					title: '（3）我方对贵方可能向服务用户私下购买或出售的数字资产的交付、质量、安全、合法性或任何其他方面没有控制权或责任。我方不负责确保与贵方私下交易的第三方买方或卖方将完成交易、交易或被授权完成交易或交易活动。如果贵方遇到从使用服务的用户处购买或出售给该用户的数字资产或服务的问题，或者如果贵方与该用户有争议，贵方应该直接与该用户解决争议。如果贵方认为用户的行为欺诈、误导或不当，或者贵方无法充分解决与第三方的争议，如果贵方有任何问题，贵方可以联系本产品在线客服寻求帮助。'
				},
				{
					title: '（4）贵方承认，没有建立担保基金或其他安排来弥补或补偿贵方由于服务的任何其他用户违约或破产而遭受的任何金钱损失。'
				}
			]
		}]
	}, 
	// 风险声明
	{
		title: '风险声明',
		content: [
			{
				content: '1.在使用我方服务进行交易或交易活动之前，贵方极有必要了解其中风险。我方在下文更详细地介绍了使用我方服务进行交易或交易活动的潜在风险。贵方应该注意到，下文描述的风险并不是详尽无遗的，也不是按任何假定的优先级顺序呈现的。我方要求贵方仔细阅读，并考虑针对本产品上的交易和交易活动是否适合贵方的特殊情况。'
			},
			{
				content: '2.如果贵方不完全了解使用我方服务的风险，贵方应该寻求独立的专业建议。'
			},
			{
				content: '3.本文中使用的大写术语具有使用术语中列出的含义，除非上下文另有要求'
			},
			{
				title: '（1）交易风险。',
				content: [
					{
						title: 'a.贵方承认并同意，贵方应自费访问和使用我方服务。交易数字资产对的损失风险可能很大。因此，根据贵方的环境、目标和财力，贵方应该仔细考虑这种交易是否适合贵方。贵方应该了解以下几点:'
					},
					{
						title: 'i.贵方可能会蒙受转移到贵方本产品账户的资金和贵方本产品账户的所有资金的全部损失。数字资产的交易价格会在短时间内大幅波动。数字资产的交易价格也可能由于各种因素而降低，包括发现不法行为、市场操纵、数字资产属性的改变、攻击，本产品或外部账户提供商或其他服务提供商暂停或停止支持数字资产，以及本产品无法控制的其他因素。'
					},
					{
						title: 'ii.在某些市场条件下，贵方可能会发现很难或无法购买或出售数字资产。例如，如果市场流动性不足，就会出现这种情况。我方不对任何数字资产的可转让性、流动性和/或可用性负责。'
					},
					{
						title: 'iii.本产品可自行决定在任何时候暂停交易或停止提供与任何数字资产相关的服务。'
					},
					{
						title: 'iv.当然，这份简短的声明不能披露与这些交易相关的所有风险和其他方面的风险。贵方全权负责确保贵方理解并接受与数字资产交易和交易活动相关的所有风险，并且能够并愿意接受和承担此类交易和数字资产交易可能产生的所有损失。'
					}
				]
			},
			{
				title: '（2）互联网传输风险和纠错。',
				content: [
					{
					title: 'a.贵方承认使用基于互联网的交易系统存在风险，包括但不限于硬件、软件和互联网连接的故障。贵方承认，本产品不对贵方在通过我方服务进行交易时可能遇到的任何通信故障、中断、错误、扭曲或延迟负责，不论是何种原因造成的。'
				}]
			},
			{
				title: '（3）与攻击服务或本产品账户相关的风险。',
				content: [
					{
					title: 'a.我方服务和/或本产品账户及其持有的资金可能会受到安全、完整性或运营方面的攻击，贵方承认并同意本产品不对由此造成的任何损失负责。'
				}]
			},
			{
				title: '（4）监管风险。',
				content: [
					{
					title: 'a.在许多司法管辖区，数字资产的监管状况不清楚或不稳定。很难预测政府当局将如何或是否监管数字资产。同样，难以预测任何政府机构如何或是否会对现有法律、法规和/或规则做出影响任何数字资产的改变。因此，数字资产可以是有价证券，也可以根据某些司法管辖区的法律进行监管。如果政府行为使继续就任何数字资产提供服务成为非法或商业上不可取的行为，本产品可能会停止就任何数字资产提供服务，或者禁止在某些管辖区内或从某些管辖区使用我方服务。根据使用规定，如果贵国的任何适用法律禁止贵方按照使用条款使用我方服务，贵方也表示并保证贵方不会使用我方服务。'
				}]
			}
		]
	}]
}


export default {
	userLaw_zh,
	userLaw_zhf,
	userLaw_en,
}
