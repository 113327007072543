<template>
	<div class="whole">
		<!-- <title-two url="/newHome" title="累计盈利" /> -->
		<!-- <common-head url="/newHome" :title="$t('tba.Bill')" :is-return="true"> -->
			<!-- <img slot="right" src="@/assets/imgs/icon/icon_log.png" alt="" /> -->
		<!-- </common-head> -->
		<van-nav-bar
		    :title="$t('tba.Bill')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="communityInfo">
					<div class="list">
						<div class="item" @click="to_bill()">
							<div class="i_left">
								<div class="yen">{{ today_profit }} U</div>
								<p>{{ $t('tba.Todaysearnings') }}</p>
							</div>
							<div class="i_right">
								<img src="../../assets/imgs/new_color/tiaozhuan_r.png" >
							</div>
						</div>
						<div class="item">
							<div class="i_left">
								<div class="yen">{{ total_profit }} U</div>
								<p>{{ $t('tba.Totalrevenue') }}</p>
							</div>
							<div class="i_right">
								<!-- <img src="../../assets/imgs/new_color/tiaozhuan_r.png" > -->
							</div>
						</div>
					</div>
				</div>
			
				<!-- <div class="titleVanRowDivStyle">
			  <van-row gutter="10">
			    <van-col span="12">
			      <div class="billNum today" @click="to_bill()">
			        <p>{{ $t("tba.todayProfit") }}(USDT)</p>
			        <span>{{ today_profit }}</span>
			      </div>
			    </van-col>
			    <van-col span="12">
			      <div class="billNum cumulative">
			        <p>{{ $t("tba.AccumulatedProfit") }}(USDT)</p>
			        <span>{{ total_profit }}</span>
			      </div>
			    </van-col>
			  </van-row>
			</div> -->
				<div class="layoutBox">
					<!-- <h3 class="caption">{{ $t('tba.Cumulativeincomedaily') }}</h3> -->
			
					<div class="titleList mainFontColor">
						<div class="top">
							<span class="kuai"></span>
							<h3 class="caption">{{ $t('tba.Cumulativeincomedaily') }}</h3>
						</div>
					</div>
			
					<van-list v-model="billLoading" :finished="billFinished" :finished-text="$t('cashFlow.NoMore')" :loading-text="$t('common.loading')" @load="onLoadcashFlow">
						<div class="content_list">
							<div class="bodyDivStyle" v-for="(item, index) in billList" :key="index">
								<div class="list_left">
									<div class="num">{{ item.profit }}</div>
									<div class="txt">{{ $t('tba.Profit') }}(USDT)</div>
								</div>
								<div class="list_right">
									<div class="txt">{{ item.ymd }}</div>
									<div class="txt">{{ item.update_at | data_fliter}}</div>
								</div>
							</div>
						</div>
					</van-list>
				</div>
		</div>
	</div>
</template>
<script>
import Loading from '../../components/Loading';
import TitleTwo from '../../components/titleTwo';
export default {
	components: {
		Loading,
		TitleTwo,
	},
	data() {
		return {
			billLoading: false,
			billFinished: false,
			billData: [],
			billList: [],
			billPage: 1,
			total_profit: 0,
			today_profit: 0
		};
	},
	watch: {
		take(news, old) {
			this.billPage = 1;
			this.billFinished = false;
			this.billList = [];
		}
	},
	filters: {
		exchangeNameFilter(web) {
			if (web == 'huobi') {
				return '火币';
			} else if (web == 'bian') {
				return '币安';
			}
		},
		data_fliter(v) {
			let time = v.split(' ');
			return time[1];
		}
	},
	created() {
		// this.getBillList();
		// this.billPage = 1;
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		to_bill() {
			this.$router.push('/bill');
		},
		profit_bill() {
			this.$router.push('/bill_sum');
		},

		getBillList() {
			this.$post2('Robot/api/index/getProfitList', {
				page: this.billPage++
			}).then(res => {
				console.log(res);
				this.billData = res.list;
				this.total_profit = res.total_profit;
				this.today_profit = res.today_profit;
				for (let i = 0; i < this.billData.length; i++) {
					this.billList.push(this.billData[i]);
				}
				// console.log(this.shouyiList);
				// 加载状态结束
				this.billLoading = false;
				// 数据全部加载完成
				
				console.log(this.billData);
				if (this.billData.length < 1) {
					this.billFinished = true;
				}
			});
		},

		onLoadcashFlow() {
			this.getBillList();
		}
	}
};
</script>
<style lang="less" scoped>
.whole {
	// background: #ffffff;
}

.title {
	background: #f6f8f9;
	height: 2rem;
	padding: 0.3rem;
}
.titleList {
	font-weight: bold;
	margin: 0 0.3rem;
	.top {
		// border-bottom: 0.01rem solid #D3D9DE;
		padding: 0.2rem 0;
		display: flex;
		align-items: center;
		.kuai {
			margin-right: 0.22rem;
			width: 5px;
			height: 0.26rem;
			background: linear-gradient(131deg, #527EFF 0%, #214BF9 100%);
		}
		.caption {
			font-size: 0.32rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			// margin-bottom: .25rem;
		}
	}
}
.billNum {
	text-align: center;
	padding: 0.4rem 0rem 0.6rem 0rem;

	p {
		font-size: 0.27rem;
		font-family: PingFang SC;
		font-weight: 500;
		color: #fff;
	}

	span {
		display: block;
		margin-top: 0.2rem;
		font-size: 0.36rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #fff;
	}
}

.cumulative {
	background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
	background-size: 100% 100%;
}

.today {
	background: url(../../assets/imgs/bill/jryl.png) no-repeat;
	background-size: 100% 100%;
}



.bodyLiStyle {
	// margin: 0.2rem 0;
	color: #666666;
	font-size: 0.24rem;
	line-height: 0.35rem;
	&.right {
		width: 2.4rem;
	}
}
.content_list {
	// padding: 0.1rem 0;
	background: #ffffff;
	border-radius: 0.32rem;
}
.bodyDivStyle {
	// border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.2rem 0;
	margin: 0 0.4rem;
	// padding-bottom: 0.1rem;
	border-bottom: 1px solid #ededed;
	.list_left {
		margin-left: 0.2rem;
		.num {
			color: #32BF88;
			font-size: 0.28rem;
			font-weight: bold;
		}
		.txt {
			color: #666666;
			font-size: 0.24rem;
		}
	}
	.list_right {
		margin-right: 0.2rem;
		.txt {
			text-align: right;
			color: #666666;
			font-size: 0.24rem;
		}
	}
}
.bodyDivStyle:last-child {
	border-bottom: none;
}

.titleVanRowDivStyle {
	width: 100%;
	background: #ffffff;
}

// ----------20200410[start]---------
.communityInfo {
	// padding: .6rem .2rem 0;
	// height: 1.72rem;
	background: #ffffff;
	// box-shadow: 0 2px 18px 0px rgba(202, 202, 202, 0.3);
	// margin: 0 .3rem .5rem;
	position: relative;
	z-index: 2;
	.list {
		padding: 0.4rem 0;
		margin: ;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.item {
			width: 50%;
			color: #2E62FF;
			font-size: 0.24rem;
			// width: 3rem;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			.yen {
				color: #2E62FF;
				font-size: 0.36rem;
				font-weight: bold;
				// font-family: Bahnschrift;
				margin-bottom: 0.08rem;
			}
			.i_right {
				margin-left: 0.32rem;
				img {
					width: 0.24rem;
					height: 0.24rem;
				}
			}
		}
		.split {
			width: 1px;
			height: 0.6rem;
			background: #e7e7e7;
		}
	}
}

.caption {
	font-size: 0.32rem;
	// font-family: PingFang SC;
	font-weight: bold;
	color: #373737;
	// margin-bottom: .25rem;
}
.layoutBox {
	// padding-top: 1.6rem;
	// margin-top: -1.6rem;
	position: relative;
	z-index: 1;
}
// ----------20200410[end]---------
</style>
