//修改昵称

<template>
  <div class="bodyStyle">
    <van-nav-bar title="修改昵称" left-arrow>
      <template #right>
        <!-- <van-icon name="search" size="18" /> -->
      </template>
    </van-nav-bar>

    <div class="vanCellStyle1">
      <van-cell title="头像" is-link />
      <van-cell title="昵称" value="张三" is-link />
      <!-- <van-cell title="单元格" is-link />
      <van-cell title="单元格" is-link /> -->
    </div>

    <div class="vanCellStyle2">
      <van-cell title="手机号" is-link />
      <van-cell title="修改密码" is-link />
      <van-cell title="修改交易密码" is-link />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "changeNickname",
  data() {
    return {};
  },
};
</script>

<style scoped>
.bodyStyle {
  background: #f1f1f1;
  height: 100%;
}

.vanCellStyle1 {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 5px;
  margin-top: 0.2rem;
  /* background: #f1f1f1; */
}

.vanCellStyle2 {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 5px;
  margin-top: 0.3rem;
  /* background: #f1f1f1; */
}

.van-icon-arrow-left {
  color: #ffffff;
}

.van-hairline--bottom {
  background: #ecbb34;
  color: #ffffff;
}

.van-nav-bar__title {
  background: #ecbb34;
  color: #ffffff;
}

/* .van-ellipsis {
  background: 3d8051;
} */
</style>